import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'components/Avatar';
import { Grid } from 'components/Grid';
import { Heading } from 'components/Heading';
import { Text } from 'components/Text';
import { cx } from 'utility';
import * as styles from './MastheadNewsletter.module.scss';

const propTypes = {
    authorName: PropTypes.string,
    authorImage: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
        }),
        url: PropTypes.string,
    }),
    className: PropTypes.string,
    title: PropTypes.string,
};

const defaultProps = {
    authorName: '',
    authorImage: {},
    className: '',
    title: '',
};

function getClasses({ className }) {
    const classes = cx(
        className,
        styles['masthead'],
    );

    return classes || undefined;
}

function MastheadNewsletter({
    authorImage,
    authorName,
    className,
    title,
}) {
    const classes = getClasses({ className });

    return (
        <div className={classes}>
            <Grid>
                <div className={styles['contentContainer']}>
                    {title && (
                        <Heading
                            className={styles['title']}
                            size={13}
                        >
                            {title}
                        </Heading>
                    )}
                    {authorName && (
                        <div className={styles['author']}>
                            <Text as="span" className={styles['name']} emphasis="medium" size={2}>By {authorName}</Text>
                            <Avatar
                                className={styles['avatar']}
                                image={authorImage}
                                size="small"
                            />
                        </div>
                    )}
                </div>
            </Grid>
        </div>
    );
}

MastheadNewsletter.propTypes = propTypes;
MastheadNewsletter.defaultProps = defaultProps;

export { MastheadNewsletter };
