// extracted by mini-css-extract-plugin
export var avatar = "Avatar-module--avatar--2HGSY";
export var image = "Avatar-module--image--2W8ck";
export var avatarSmall = "Avatar-module--avatarSmall--10D-W";
export var icon = "Avatar-module--icon--_Nyyt";
export var avatarMedium = "Avatar-module--avatarMedium--17HZ7";
export var avatarLarge = "Avatar-module--avatarLarge--OQ20I";
export var avatarOnSurface = "Avatar-module--avatarOnSurface--MKg6g";
export var show = "Avatar-module--show--1brqT";
export var showFadeInDown = "Avatar-module--show-fade-in-down--1g032";
export var showFadeInUp = "Avatar-module--show-fade-in-up--tYYA1";
export var hideFadeOut = "Avatar-module--hide-fade-out--2uhrp";