import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue3({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="New social plan, website tech and more"
            pageSocialImage=""
            pageTitle="Issue #3"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #3"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "February marks my 6th month as a full-time freelancer, wow time has flown. It feels like I’ve been rushed off my feet for the past few months, but now I’m trying to set aside some time to work on my own things.", type: "paragraph"},
                        {spans: [], text: "Why? Well, this was one of the reasons I wanted to go freelance in the first place, so I’d have more control over my time and spend it on things I enjoy and find interesting.", type: "paragraph"},
                        {spans: [], text: "I guess all I can say for now, is stay tuned for more updates.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at February"
                    content={[
                        {spans: [], text: "👷 Work", type: "heading4"},
                        {spans: [], text: "February has been a really exciting month work-wise. I regained 3 days a week that I had assigned to a contract project for the past 3 months – which means I now have more say in the work I’ve been doing.", type: "paragraph"},
                        {spans: [], text: "The first week saw me wrapping up some design work for an accessibility project – it was great to do some product design work and use more of Figma’s prototyping functionality.", type: "paragraph"},
                        {spans: [], text: "Week 2 - 4 was mainly split between a Gatsby, Prismic project and the agency I worked for before I started freelancing – these were both new engagements that I started in February.", type: "paragraph"},
                        {spans: [], text: "I made a ton of headway on the Gatsby, Prismic project and have pretty much wrapped it up in the 3 weeks I’ve been working on it.", type: "paragraph"},
                        {spans: [], text: "I then spent a day or 2 over the 3 weeks working with my previous agency and it was actually really fun.", type: "paragraph"},
                        {spans: [], text: "I got to do some design work and development work – the most interesting piece involved working with a few of Google Maps APIs to render points on a map and update data based on the distance between the user’s location and other locations (without giving any specifics away).", type: "paragraph"},
                        {spans: [], text: "✍️ Social planning", type: "heading4"},
                        {spans: [], text: "I set myself a goal at the start of the year to grow my Instagram account... and well we’re 2 months into the year and I’ve not made any progress towards it.", type: "paragraph"},
                        {spans: [], text: "As I made a lot of headway with client work, I decided to spend some time in the last week of February creating content for Instagram.", type: "paragraph"},
                        {spans: [], text: "I wanted to make sure I started with a solid foundation, so I created a bunch of reusable post templates in Figma (this will also save me a lot of time when creating new posts).", type: "paragraph"},
                        {spans: [], text: "So far I’ve created enough Instagram posts for the first week of March (starting tomorrow) and have a further 2 weeks worth of post ideas in the bag.", type: "paragraph"},
                        {spans: [{
                            start: 77,
                            end: 86,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.instagram.com/robcsimps/",
                            },
                        }], text: "If you’re intrigued by what I’ll be sharing make sure to give me a follow on Instagram.", type: "paragraph"},
                        {spans: [], text: "💻 Website progress", type: "heading4"},
                        {spans: [], text: "I’ve been working on content and designs for my new website for several months now (when I have spare time). This update will see me splitting my site into 2 – my business site and my personal site.", type: "paragraph"},
                        {spans: [], text: "Both sites will use components from the same Storybook and I’m currently looking at setting up a Monorepo so both projects can live inside the same project.", type: "paragraph"},
                        {spans: [], text: "My plan is to start development in March and share as I go. Tech-wise, I’m going to be using NextJS, Vercel, Prismic and Storybook. To date, I‘ve mostly worked with Gatsby, but I want to learn some new things and side projects are the best way to do that.", type: "paragraph"},
                        {spans: [], text: "💌 Buttondown", type: "heading4"},
                        {spans: [{
                            start: 73,
                            end: 83,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://buttondown.email/",
                            },
                        }], text: "Following on from Plausible in last months newsletter, I stumbled across Buttondown – a privacy-first newsletter platform. I’ve not moved over to Buttondown yet, but I like their approach.", type: "paragraph"},
                        {spans: [], text: "My main concern is how much creative control I’d have over how my newsletter looks – I guess I’ll have to setup an account and take a look. If you know of any other good newsletter platforms please let me know.", type: "paragraph"},
                        {spans: [], text: "🚗 New car", type: "heading4"},
                        {spans: [], text: "At the start of December my wife was in an accident (she’s fine), someone went into the back of her and it’s been a mess getting all of it sorted. In the end, they decided to write-off her car.", type: "paragraph"},
                        {spans: [], text: "This meant we now needed to get a new one – something I was hoping to put off for a little while longer.", type: "paragraph"},
                        {spans: [], text: "We needed something a bit bigger because travelling any sort of distance with a 2-year-old usually requires quite a bit of luggage.", type: "paragraph"},
                        {spans: [], text: "But looking around at family-sized cars in our price range they all looked a bit boring... apart from the Mazda CX5.", type: "paragraph"},
                        {spans: [], text: "I really liked the way this car looked and my wife had a Mazda previously so we knew what to expect. She now mostly uses my “old” car for commuting to work and we keep the Mazda for family trips to the shop... because where else can you go in lockdown.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 February goals"
                    checkList={[
                        {complete: true, text: "✍️ Finish writing content for my new website"},
                        {complete: false, text: "🏋️ Exercise 5 days a week"},
                        {complete: true, text: "📙 Read/take action on reading for 25 minutes a day, 3 days a week"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to March"
                    content={[
                        {spans: [], text: "It looks like I’ll be spending the majority of March deep in Gatsby and Prismic. I’m helping an agency expand one of their client’s sites by dividing it into 2 areas “business” and “personal”.", type: "paragraph"},
                        {spans: [], text: "I’ll also be wrapping up the Gatsby, Prismic project I started in February (my most profitable project to date).", type: "paragraph"},
                        {spans: [], text: "Then mid-month I’ll be kicking off a new Gatsby, Prismic project that has been booked in since December – it’ll have quite a bit of animation so I’m looking forward to it.", type: "paragraph"},
                        {spans: [], text: "Most importantly I’ll spend some time trying to book in some new work for April – one of the hardest things as a freelancer is booking in work further down the line as the majority of leads that come in want to start ASAP (I’ll be sharing a post on Instagram this month on how I try and mitigate this).", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 March goals"
                    checkList={[
                        {complete: false, text: "📸 Share 27 Instagram posts"},
                        {complete: false, text: "🏗 Build 15 components for my new website"},
                        {complete: false, text: "🌟 Land a decent sized project for April"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="As a freelancer, it’s good to have a number of different clients – but not too many that you struggle to manage them all. This way you can manage cash flow by sending out invoices throughout the month."
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/f47e2b69-fdc6-427a-960c-160d184da803_feb-2021-income.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £6.9k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/adc1d587-2034-4c1b-82df-c87b1cbc36de_vivo-protein.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Vivo acai and blueberry vegan protein powder", type: "heading4"},
                        {spans: [], text: "This month I tried a new flavour of Vivo’s perform protein powder – acai and blueberry. I tend to have mine with water after a workout and this along with the cocoa flavour taste really nice. If you’re used to having whey protein powder, this is far less sweet and could taste a bit odd to begin with.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.vivolife.co.uk/products/perform",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/ccb408db-fc83-4b19-b3a9-287a2e7a3ec1_book-sustainable-web-design.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Sustainable web design by Tom Greenwood", type: "heading4"},
                        {spans: [], text: "This was one of those books I couldn’t put down. I managed to read the entire thing in about 2 weeks and now plan on re-reading it. It’s very interesting to see the environmental impact our digital projects have and what we can do about it – highly recommend picking up a copy.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://abookapart.com/products/sustainable-web-design",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue3;
