import React from 'react';

import { CheckIcon } from 'icons';
import { Button } from 'components/Button';
import { Content } from 'components/Content';
import { Grid } from 'components/Grid';
import { Heading, HeadingBoundary } from 'components/Heading';
import { Image } from 'components/Image';
import { Text } from 'components/Text';
import { cx } from 'utility';
import * as styles from './ContentBlock.module.scss';

function getClasses({ className }) {
    const classes = cx(
        className,
        styles['content'],
    );

    return classes || undefined;
}

function ContentBlock({
    buttonText,
    buttonUrl,
    className,
    checkList,
    content,
    contentSize,
    gridClassName,
    image,
    quote,
    title,
}) {
    const classes = getClasses({ className });

    return (
        <div className={classes}>
            <HeadingBoundary>
                <Grid className={gridClassName}>
                    <div className={styles['titleContainer']}>
                        <Heading
                            className={styles['title']}
                            size={7}
                        >
                            {title}
                        </Heading>
                    </div>
                </Grid>
                <Grid className={styles['contentGrid']} background={true} block={true}>
                    <div className={styles['contentContainer']}>
                        <Grid>
                            <div className={`${styles['contentWrapper']} ${contentSize === 'large' && styles['large']}`}>
                                {image?.url && (
                                    <div className={styles['imageContainer']}>
                                        <Image
                                            className={styles['image']}
                                            image={image}
                                            size="small"
                                        />
                                    </div>
                                )}
                                {content && (
                                    <Content content={content} />
                                )}
                                {quote && (
                                    <Text as="p" className={styles['quote']} emphasis="low" size={6}>{quote}</Text>
                                )}
                                {checkList && (
                                    <ul className={styles['checkList']}>
                                        {checkList.map(({ complete, text }, index) => {
                                            return (
                                                <Text as="li" className={`${styles['checkItem']} ${complete && styles['complete']}`} key={`content-block-check-list-${index}-${text}`} size={4}>
                                                    <div className={styles['checkBox']}>
                                                        {complete && <CheckIcon className={styles['checkIcon']} />}
                                                    </div>
                                                    <div><span>{text}</span></div>
                                                </Text>
                                            )
                                        })}
                                    </ul>
                                )}
                                {(buttonText && (buttonUrl?.url || buttonUrl?.uid)) && (
                                    <Button className={styles['button']} color="secondary" style="outline" showExternalIcon={false} to={buttonUrl}>{buttonText}</Button>
                                )}
                            </div>
                        </Grid>
                    </div>
                </Grid>
            </HeadingBoundary>
        </div>
    );
}

export { ContentBlock };
