import React from 'react';
import PropTypes from 'prop-types';

import { UserIcon } from 'icons';
import { Image } from '../Image';
import { cx, capitalizeFirstLetter } from 'utility';
import * as styles from './Avatar.module.scss';

const propTypes = {
    background: PropTypes.bool,
    className: PropTypes.string,
    image: PropTypes.PropTypes.shape({
        alt: PropTypes.string,
        dimensions: PropTypes.shape({
            height: PropTypes.number,
            width: PropTypes.number,
        }),
        url: PropTypes.string,
    }),
    size: PropTypes.oneOf(['large', 'medium', 'small']),
};

const defaultProps = {
    background: false,
    className: '',
    image: {},
    size: 'medium',
};

function getClasses({ className, background, size }) {
    const classes = cx(
        className,
        styles['avatar'],
        background && styles['avatarOnSurface'],
        styles[`avatar${capitalizeFirstLetter(size)}`]
    );

    return classes || undefined;
}

function Avatar({
    background,
    className,
    image,
    size
}) {
    const classes = getClasses({ className, background, size });
    let imageSize = 128;

    if (size === 'small') {
        imageSize = 64;
    }
    else if (size === 'large') {
        imageSize = 192;
    }

    return (
        <div className={classes}>
            {!image?.url && (
                <UserIcon className={styles['icon']} />
            )}
            {image?.url && (
                <Image
                    className={styles['image']}
                    image={image}
                    size={imageSize}
                />
            )}
        </div>
    );
}

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

export { Avatar };
